$(function () {
	$(document).on("click", ".s31-checkout-deliverypayment-itemDescOpening", function () {
		$(this).parent().next(".s31-checkout-deliverypayment-itemDesc").toggleClass("s31-active");
	});
	
	$(document).on("change", ".s31-checkout-deliverypayment-country", function () {
		var dataHref = $(this).attr('data-href');
		
		window.location.href = dataHref;
	});
	
	let shippingAndPaymentsMethods = document.querySelectorAll("input[name='s31shop_checkout_select_shipping_and_payment[shipments][0][method]'], input[name='s31shop_checkout_select_shipping_and_payment[payments][0][method]']");
	for (var i = 0; i < shippingAndPaymentsMethods.length; i++) {
		let shippingAndPaymentsMethod = shippingAndPaymentsMethods[i];

		shippingAndPaymentsMethod.addEventListener("change", function() {
			if ($(this).parents(".s31-checkout-deliverypayment-item").hasClass("s31-checkout-deliverypayment-item-disPoints") && $(this).parents(".s31-checkout-deliverypayment-item").find(".s31-checkout-deliverypayment-itemDispoint-selected").length == 0) {
				return;
			}

			$.ajax({
				method: 'POST',
				data: $(this.form).serialize(),
				cache: false,
				url: "/ajax" + this.form.getAttribute("action"),
				success: function (response) {
					var $payload = $(response);
					
					if ($payload.find(".s31-checkout-summary-box").length > 0) {
						$(".s31-checkout-summary-box").html($payload.find(".s31-checkout-summary-box").html());
					}

					$(".s31-checkout-delivery").children(".s31-checkout-deliverypayment-item:not(.s31-checkout-deliverypayment-itemActive)").each(function(i) {
						$(this).find(".s31-dispensing-point-content").empty();
					});
				},
				error: function (jqXHR) {

				},
				complete: function() {

				}
			});
		});
	}

	$(document).on("change", ".s31-checkout-delivery .s31-checkout-delivery-method input[type='radio']", function() {
		$(".s31-checkout-delivery .s31-checkout-deliverypayment-item.s31-checkout-deliverypayment-itemActive").removeClass("s31-checkout-deliverypayment-itemActive");

		if (this.checked) {
			$(this).parents(".s31-checkout-deliverypayment-item").addClass("s31-checkout-deliverypayment-itemActive");
			//showEgliblePayments(this.value);
		}
	});

	if ($(".s31-checkout-delivery .s31-checkout-delivery-method input[type='radio']:checked").length == 1) {
		$(".s31-checkout-delivery .s31-checkout-delivery-method input[type='radio']:checked").parents(".s31-checkout-deliverypayment-item").addClass("s31-checkout-deliverypayment-itemActive");
		//showEgliblePayments($(".s31-checkout-delivery .s31-checkout-delivery-method input[type='radio']:checked").val());
	}

	$(document).on("change", ".s31-checkout-payment .s31-checkout-delivery-method input[type='radio']", function() {
		$(".s31-checkout-payment .s31-checkout-deliverypayment-item.s31-checkout-deliverypayment-itemActive").removeClass("s31-checkout-deliverypayment-itemActive");

		if (this.checked) {
			$(this).parents(".s31-checkout-deliverypayment-item").addClass("s31-checkout-deliverypayment-itemActive");
		}
	});

	if ($(".s31-checkout-payment .s31-checkout-delivery-method input[type='radio']:checked").length == 1) {
		$(".s31-checkout-payment .s31-checkout-delivery-method input[type='radio']:checked").parents(".s31-checkout-deliverypayment-item").addClass("s31-checkout-deliverypayment-itemActive");
	}

	function showEgliblePayments(shippingMethodCode) {
		if (shippingPayments.hasOwnProperty(shippingMethodCode)) {
			$(".s31-checkout-payment .s31-checkout-deliverypayment-item").addClass("d-none");

			for (var i in shippingPayments[shippingMethodCode]) {
				var paymentMethodCode = shippingPayments[shippingMethodCode][i];

				$(".s31-checkout-payment input[type='radio'][value='" + paymentMethodCode + "']").parents(".s31-checkout-deliverypayment-item").removeClass("d-none");
			}
		} else {
			$(".s31-checkout-payment .s31-checkout-deliverypayment-item").removeClass("d-none");
		}

		if ($(".s31-checkout-payment input[type='radio']:checked").parents(".s31-checkout-deliverypayment-item").hasClass("d-none")) {
			$(".s31-checkout-payment .s31-checkout-deliverypayment-item:not(.d-none) input[type='radio']").eq(0).click();
		}
	}

});