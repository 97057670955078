$(function () {
	$(document).on("click", ".s31-calendar-windows:not(.s31-closed):not(.s31-future), .s31-calendar-windows-behind:not(.s31-closed):not(.s31-future)", function () {
        let clickedDay = $(this).attr("data-day");

        $("#s31-calendarPopup").modal("show");
        //$("#s31-calendarPopup-title1").html(eventData[clickedDay-1][0]["title1"]);
        $("#s31-calendarPopup-title2").html(eventData[clickedDay-1][0]["title2"]);
        $("#s31-calendarPopup-link").attr("href", eventData[clickedDay-1][0]["link"]);
        $("#s31-calendarPopup-validTo").html(eventData[clickedDay-1][0]["validTo"]);
        $("#s31-calendar-window-" + clickedDay).addClass("s31-opened");

        
        var currentDayArray = [parseInt(clickedDay)];
        if (typeof $.cookie('calendarActivatedDays') === 'undefined'){
            var activatedDays = [0];
        } else {
            var activatedDays = JSON.parse($.cookie('calendarActivatedDays'));
        }
        var activatedDaysMerged = activatedDays.concat(currentDayArray);
        $.cookie('calendarActivatedDays', activatedDaysMerged, {expires: 10000, path: '/'});
	});

    $(document).on("click", ".s31-future", function () {
        $("#s31-calendarPopupFuture").modal("show");
	});

    if ($('.s31-calendar-counter').length > 0) {
        const countDownDate = new Date(calendarDate).getTime();
    
        let x = setInterval(function() {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let breakEl = '<br class="s31-br-mobile" />'
            let dotsEl = '<span class="dots"> : </span>';
    
            let daysWord = cmsTimerDaysMore
            if (days == 1) {
                daysWord = cmsTimerDay
            } else if (days > 1 && days < 5) {
                daysWord = cmsTimerDays
            }

            let hoursWord = cmsTimerHoursMore
            if (hours == 1) {
                hoursWord = cmsTimerHour
            } else if (hours > 1 && hours < 5) {
                hoursWord = cmsTimerHours
            }

            let minutesWord = cmsTimerMinutesMore
            if (minutes == 1) {
                minutesWord = cmsTimerMinute
            } else if (minutes > 1 && minutes < 5) {
                minutesWord = cmsTimerMinutes
            }

            let secondsWord = cmsTimerSecondsMore
            if (seconds == 1) {
                secondsWord = cmsTimerSecond
            } else if (seconds > 1 && seconds < 5) {
                secondsWord = cmsTimerSeconds
            }
    
            if (days > 0) {
                document.getElementById("counterDays").innerHTML = days + ' ' + daysWord + breakEl +  dotsEl;
            } else {
                document.getElementById("counterDays").innerHTML = "";
            }
            if (days > 0 || hours > 0) {
                document.getElementById("counterHours").innerHTML = hours + ' ' + hoursWord + breakEl +  dotsEl;
            } else {
                document.getElementById("counterHours").innerHTML = "";
            }
            if (days > 0 || hours > 0 || minutes > 0) {
                document.getElementById("counterMinutes").innerHTML = minutes + ' ' + minutesWord + breakEl +  dotsEl;
            } else {
                document.getElementById("counterMinutes").innerHTML = "";
            }
            document.getElementById("counterSeconds").innerHTML = '<span class="s31-secondsblock">' + seconds + '</span> ' + secondsWord + breakEl;
    
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("s31-calendar-counter").innerHTML = calendarEnd;
            }
        }, 1000);
    }
});