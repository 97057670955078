var syliusCartSubmittedBy = null;

$(function () {
	//Matchmedia korespondující s mediaquaries pro bootstrap

	if (mqxl.matches) {
		//XL nad 1199px

	} else if (mqlg.matches) {
		//LG nad 991px

	} else if (mqmd.matches) {
		//MD nad 767px

	} else if (mqsm.matches) {
		//SM nad 575px

	} else {
		//XS do 575px

	}

	// Ostatní JS společné pro všechny zařízení
	//----------------------------------------------------------------------

	var syliusCartActiveRequest = false;

	$("form[name='sylius_cart']").on("submit", function (e) {
		e.preventDefault();

		var $this = $(this);
		var href = "/ajax" + $this.attr('action');

		if (syliusCartActiveRequest) {
			return;
		}

		syliusCartActiveRequest = true;

		$.ajax({
			method: 'PATCH',
			data: $this.serialize(),
			cache: false,
			url: href,
			success: function (response) {
				var $payload = $(response);

				processCartSummaryResponse($payload);
			},
			error: function (jqXHR) {

			},
			complete: function() {
				syliusCartActiveRequest = false;
				syliusCartSubmittedBy = null;
			}
		});

		return false;
	});

	$(document).on("click", "#s31-cart-coupon-button", function (e) {
		syliusCartSubmittedBy = "coupon-button";

		$("#s31-cart-coupon-button").parent().removeClass("field error");
		$("#s31-cart-coupon-button").next(".alert").remove();

		if ($.trim($("#sylius_cart_actionCode").val()) == "") {
			e.preventDefault();
			e.stopPropagation();

			$("#s31-cart-coupon-button").parent().addClass("field error");
			$("#s31-cart-coupon-button").after("<div class=\"alert alert-danger pointing label sylius-validation-error\">" + actionCodeError +"</div>");

			return false;
		}

		if ($(this).data("remove")) {
			e.preventDefault();

			$.ajax({
				method: 'POST',
				cache: false,
				url: $(this).data("remove"),
				success: function (response) {
					var $payload = $(response);

					processCartSummaryResponse($payload);
				},
				error: function (jqXHR) {
				}
			});
		}
	});

	if ($(".s31-thankyou-redirect-timer").length) {
		var counter = 10;
		var interval = setInterval(function() {
			counter--;
			$(".s31-thankyou-redirect-timer").text("0" + counter);
			if (counter == 0) {
				var hostname = window.location.protocol + "//" + window.location.hostname;
				var orderUrl = $("#s31-sendOrderButt").attr("href");
				window.location.replace(hostname + orderUrl);
				clearInterval(interval);
			}
		}, 1000);
	}
});

var processCartSummaryResponse = function($payload) {
	var error = false;

	if ($('#s31-cart-couponLabel input').is(":checked")) {
		$payload.find("#s31-cart-couponLabel input").attr("checked", "checked");
		$payload.find("#s31-cart-couponForm").css("display", "block");
	}

	if ($payload.find("#s31-cart-coupon-pane").length > 0) {
		$("#s31-cart-coupon-pane").html($payload.find("#s31-cart-coupon-pane").html());
	}

	if ($payload.find(".s31-cart-articles .sylius-validation-error").length > 0) {
		error = true;

		$(".s31-cart-articles .sylius-validation-error").remove();
		if (syliusCartSubmittedBy == "coupon-button") {
			$("#s31-cart-coupon-button").parent().addClass("field error");
			$("#s31-cart-coupon-button").after($payload.find(".s31-cart-articles .sylius-validation-error"));
		} else {
			$payload.find(".s31-cart-articles .sylius-validation-error").each(function (index, element) {
				$("form[name=sylius_cart] .s31-cart-articles").prepend(element);
			});
		}
	}

	if ($payload.find(".s31-cart-articles-freeDelivery").length > 0) {
		if ($(".s31-cart-articles-freeDelivery .s31-cart-articles-freeDeliveryBarDone").length > 0 && $payload.find(".s31-cart-articles-freeDelivery .s31-cart-articles-freeDeliveryBarDone").length > 0) {
			$(".s31-cart-articles-freeDelivery .s31-cart-articles-freeDeliveryText").html($payload.find(".s31-cart-articles-freeDelivery .s31-cart-articles-freeDeliveryText").html());
			$(".s31-cart-articles-freeDelivery .s31-cart-articles-freeDeliveryBarDone").attr("style", $payload.find(".s31-cart-articles-freeDelivery .s31-cart-articles-freeDeliveryBarDone").attr("style"));
			$(".s31-cart-articles-freeDelivery").attr("data-percentage-amount", $payload.find(".s31-cart-articles-freeDelivery").attr("data-percentage-amount"));
			var percentage = $(".s31-cart-articles-freeDelivery").attr("data-percentage-amount");
			if (percentage <= 60) {
				$(".s31-cart-articles-freeDelivery").addClass('lower');
			} else {
				$(".s31-cart-articles-freeDelivery").removeClass('lower');
			}
		} else {
			$(".s31-cart-articles-freeDelivery").html($payload.find(".s31-cart-articles-freeDelivery").html());
		}
	}

	if ($payload.find(".s31-cart-total").length > 0) {
		$(".s31-cart-total").html($payload.find(".s31-cart-total").html());
		$(".s31-cartHeaderTotalprice").html($payload.find("#sylius-cart-grand-total").html());
		$(".s31-ico-text-headingMobile strong").html($payload.find("#sylius-cart-grand-total").html());
		if ("#s31-vo-delivery-missing") { 
			var voNoVatPrice = $("#sylius-cart-tax-total").attr("data-vo-total") / 100;
			if (voNoVatPrice < 5000) {
				$(".s31-flash-messages-warning").removeClass("d-none");
				var voMissingPrice = 5000 - Math.round(voNoVatPrice);
				result = voMissingPrice.toLocaleString() ;
				$("#s31-vo-delivery-missing").html(result + ' Kč');
			} else {
				$(".s31-flash-messages-warning").addClass("d-none");
			}
		}
	}

	if ($payload.find(".s31-cartTotalQuantity").length > 0) {
		$("#s31-cart-layer .s31-cartTotalQuantity").html($payload.find(".s31-cartTotalQuantity").html());
		$("#cart-button .s31-ico-count").html($payload.find(".s31-cartTotalQuantity").html());
		$(".s31-cartTotalQuantityWord").html($payload.find(".s31-cartTotalQuantityWord").html());
	}
	
	if ($payload.find(".s31-cart-gift-pane").length > 0) {
		$(".s31-cart-gift-pane").html($payload.find(".s31-cart-gift-pane").html());
	}

	if (error == false) {
		$(".s31-cart-articles .sylius-validation-error").remove();

		$("#sylius-cart-items").html($payload.find("#sylius-cart-items").html());
		$("#sylius-coupon").html($payload.find("#sylius-coupon").html());
		$(".s31-cart-total").html($payload.find(".s31-cart-total").eq(0).html());
	}

	if ($payload.find(".gtm-cart-push").length > 0) {
		$("#sylius-cart-items").prepend($payload.find(".gtm-cart-push"));
	}

}