function fillNodesWithData(selectors, value) {
	var nodes = document.querySelectorAll(selectors);

	for (var i = 0; i < nodes.length; i++) {
		if (nodes[i].tagName != 'INPUT') {
			nodes[i].innerHTML = value;
		} else {
			nodes[i].value = value;
		}
	}

	if (selectors == ".packeta-selector-branch-id") {
		DispensingPoints.select(value);
	}
}

var DispensingPoints = function () {
	var dispensingPointLink = ".s31-select-dispensing-point",
		$currentOpenAnchor;

	return {
		init: function () {
			$(dispensingPointLink).on("click", function (e) {
				$(this).parents(".s31-checkout-deliverypayment-item").find("input[type='radio']").click();

				$currentOpenAnchor = $(this);
			});
		},
		select: function (branchId) {
			$('body, html').animate({
				scrollTop: $(".s31-checkout-delivery").offset().top - 50
			}, 1);

			$.ajax({
				method: "PUT",
				cache: false,
				url: "/ajax/shipping/dispensing-point-ext/" + branchId,
				success: function (response) {
					$currentOpenAnchor.parent().find(".s31-dispensing-point-content").html(response);
					$currentOpenAnchor.parents(".s31-checkout-deliverypayment-item").find(".s31-checkout-delivery-method input")[0].dispatchEvent(new Event('change'));
				},
				error: function (jqXHR) {
					$currentOpenAnchor.parent().find(".s31-dispensing-point-content").html("Výdejní místo se nepodařilo nastavit. Zkuste to, prosím, později nebo vyberte jiné.");
				}
			});
		}
	};
}();

$(function () {
	DispensingPoints.init();
});

