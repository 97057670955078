$(function () {
    $(".add-embroidery").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();

        let list = $($(this).attr("data-list-selector"));
        let counter = list.data("widget-counter") || list.children().length;
        
        let newItem = list.attr("data-prototype");
        newItem = newItem.replace(/__name__/g, counter);
        newItem = newItem.replace(/__embroidery__/g, counter+1);
        
        counter++;
        list.data("widget-counter", counter);
        
        let newBlock = $(list.attr("data-widget-tags")).html(newItem);
        newBlock.appendTo(list);
    });

    $(document).on("click", ".remove-embroidery", function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).parents(".item").remove();
    });

    //konfigurátor
    $(document).on("keyup", ".s31-embroideryInput1", function() {
        let embId = $(this).data("embroidery");
        let value = $.trim($(this).val());


        $(".s31-embroidery-" + embId + "-Line1").html(value);

        if (value > "") {
            $(this).parent().find(".alert").remove();
            $(this).parent().removeClass("error").addClass("success");
            $(this).parent().parent().next().removeClass("disabled");
            $(this).parent().parent().next().find(".s31-embroideryInput2").removeAttr("disabled");
        } else {
            $(this).parent().find(".alert").remove();
            $(this).parent().append(embErrorMessage);
            $(this).parent().removeClass("success").addClass("error");
            input2val = $.trim($(this).parent().parent().next().find(".s31-embroideryInput2").val());
            if (input2val == "") {
                $(this).parent().parent().next().addClass("disabled");
                $(this).parent().parent().next().find(".s31-embroideryInput2").attr("disabled", "disabled");
            }
        }
    });

    $(document).on("click", ".embroidery-slider-slide", function() {
        let embId = $(this).data("embroidery");
        let value = $.trim($(this).data("value"));
        let lines = $.trim($(this).data("lines"));
        let text = $.trim($(this).find("span").text());

        $(".embroidery-slider-slide").removeClass("active");
        $(this).addClass("active");

        $(".s31-embroidery-" + embId + "-Line1").html('<img src="/assets/shop/img/embroideries/' + value + '.png" />');
        $("#sylius_add_to_cart_cartItem_embroideries_" + embId + "_line1").val("Symbol: " + text);

        $(this).parent().parent().parent().removeClass("error");
        $(this).parent().parent().parent().find(".alert").remove();

        if (lines == "2") {
            $(this).parent().parent().parent().parent().next().next().removeClass("disabled");
            $(this).parent().parent().parent().parent().next().next().find(".s31-embroideryInput2").removeAttr("disabled");
        }
    });

    $(document).on("change", "input[name*='embroideryType']", function() {
        let embId = $(this).data("embroidery");
        let value = $.trim($(this).val());
        let lines = $.trim($(this).data("lines"));

        $("#s31-embroidery-line1-0 .field").removeClass("success");

        if (value == "text") {
            $("#s31-embroidery-symbol-" + embId + " .embroidery-slider-slide").removeClass("active");
            $("#s31-embroidery-line1-" + embId).show();
            $("#s31-embroidery-symbol-" + embId).hide();
            $("#sylius_add_to_cart_cartItem_embroideries_" + embId + "_line1").val("");
            $(".s31-embroidery-" + embId + "-Line1").html('');
            $("select[id*='sylius_add_to_cart_cartItem_embroideries_symbol_" + embId + "']").val(0).change();

            if (lines == "1") {
                $("#s31-embroidery-fontColor-" + embId).show();
            }

            clearInterval(sliderInterval);
        } else {
            $(".s31-embroidery-" + embId + "-Line1").html("");
            $("#s31-embroidery-line1-" + embId).hide();
            $("#s31-embroidery-symbol-" + embId).show();
            $("#sylius_add_to_cart_cartItem_embroideries_" + embId + "_line1").val("");

            if (lines == "1") {
                $("#s31-embroidery-fontColor-" + embId).hide();
            }

            checkrightButtonStatus(embId);
            slideAuto(embId);
        }
    });

    $(document).on("click", ".embroidery-slider-next", function() {
        let embId = $(this).data("slider-id");
        
        $("#embroidery-slider-" + embId).animate({scrollLeft: "+=100px"}, "fast");
    });

    $(document).on("click", ".embroidery-slider-prev", function() {
        let embId = $(this).data("slider-id");
        
        $("#embroidery-slider-" + embId).animate({scrollLeft: "-=100px"}, "fast");
    });
    
    

    $(document).on("keyup", ".s31-embroideryInput2", function() {
        let embId = $(this).data("embroidery");
        let value = $.trim($(this).val());

        $(".s31-embroidery-" + embId + "-Line2").html(value);

        if (value > "") {
            $(this).parent().addClass("success");
        } else {
            $(this).parent().removeClass("success");
        }
    });

    $(document).on("change", ".s31-embroideryColor", function() {
        let embId = $(this).data("embroidery");
        let value = $(this).val();

        if (value == "seda") {
            value = "#c7cccc";
        } else if (value == "antracitova") {
            value = "#8c9398";
        } else if (value == "ruzova") {
            value = "#dea3a5";
        } else if (value == "modra") {
            value = "#2573d5";
        } else if (value == "bila") {
            value = "#ffffff";
        }

        $(".s31-embroidery-" + embId + "-Line1").css("color", value);
        $(".s31-embroidery-" + embId + "-Line2").css("color", value);
    });


    if (mqxl.matches) {
        //XL nad 1199px
        

    $(document).on("change", ".s31-embroiderySize", function() {
        let embId = $(this).data("embroidery");
        let value = $(this).val();

        if (value == "5,5 cm") {
            value = "50px";
        } else if (value == " 5 cm") {
            value = "50px";
        } else if (value == "4,5 cm") {
            value = "45px";
        } else if (value == "4 cm") {
            value = "40px";
        } else if (value == "3,5 cm") {
            value = "35px";
        } else if (value == "3 cm") {
            value = "30px";
        } else if (value == "2,7 cm") {
            value = "27px";
        } else if (value == "2 cm") {
            value = "20px";
        }

        $(".s31-embroidery-" + embId + "-Line1").css("font-size", value);
        $(".s31-embroidery-" + embId + "-Line2").css("font-size", value);
    });


	} else {
		//XS do 575px



    $(document).on("change", ".s31-embroiderySize", function() {
        let embId = $(this).data("embroidery");
        let value = $(this).val();

        if (value == "5,5 cm") {
            value = "5.5vw";
        } else if (value == " 5 cm") {
            value = "5vw";
        } else if (value == "4,5 cm") {
            value = "4.5vw";
        } else if (value == "4 cm") {
            value = "4vw";
        } else if (value == "3,5 cm") {
            value = "3.5vw";
        } else if (value == "3 cm") {
            value = "3vw";
        } else if (value == "2,7 cm") {
            value = "2.7vw";
        } else if (value == "2 cm") {
            value = "2vw";
        }

        $(".s31-embroidery-" + embId + "-Line1").css("font-size", value);
        $(".s31-embroidery-" + embId + "-Line2").css("font-size", value);
    });
	}
});

function checkrightButtonStatus(embId) {
    var sliderWidth = $("#embroidery-slider-" + embId).width();
    var sliderWrapperWidth = document.getElementById("embroidery-slider-" + embId).children[0].scrollWidth;

    if (sliderWrapperWidth > sliderWidth) {
        $("#embroidery-slider-next-" + embId).removeClass("button-disabled");
    }

}

function checkBothButtons(embId) {
    var sliderWidth = $("#embroidery-slider-" + embId).width();
    var sliderWrapperLeft = $("#embroidery-slider-" + embId + " .embroidery-slider-wrapper").position().left;
    var sliderWrapperWidth = document.getElementById("embroidery-slider-" + embId).children[0].scrollWidth;
    var right = sliderWrapperWidth + sliderWrapperLeft - 41;

    if (sliderWrapperLeft < 40) {
        $("#embroidery-slider-prev-" + embId).removeClass("button-disabled");
    } else {
        $("#embroidery-slider-prev-" + embId).addClass("button-disabled");
    }

    if (right < sliderWidth) {
        $("#embroidery-slider-next-" + embId).addClass("button-disabled");
    } else {
        $("#embroidery-slider-next-" + embId).removeClass("button-disabled");
    }
}

var sliderInterval;
function slideAuto(embId) {
    clearInterval(sliderInterval);
    sliderInterval = setInterval(function () {
        $("#embroidery-slider-" + embId).animate({scrollLeft: "+=100px"}, "fast");
    }, 3000);
}